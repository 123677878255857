<mat-nav-list>

  <mat-divider></mat-divider>
  <a mat-list-item href="/">Home</a>

  <mat-divider></mat-divider>

  <a mat-list-item href="https://tonyswebstudios.blogspot.com/" target="_blank" >
    <mat-icon>search_outline</mat-icon>
    Search Blog
  </a>

  <mat-divider></mat-divider>

  <h2 matSubheader>Miinistries</h2>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/word">Word &amp; Sacrament</a>
  <a mat-list-item href="/fitness">Fitness</a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/mindfulness">Mindfulness</a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/wedding">Weddings</a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/outreach">Outreach</a>
  <a mat-list-item routerLinkActive="list-item-active" routerLink="/devotionals">Devotionals</a>

  <mat-divider></mat-divider>
</mat-nav-list>

