<mat-toolbar >
  <mat-toolbar-row class="purple-background">
    <button mat-icon-button (click)="toggleSidebar()">
      <mat-icon>menu</mat-icon>
    </button>
    <span> Faith Community Church </span>
    <div fxFlex flexLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
       <!--<li>
          <button mat-icon-button>
            <mat-icon>settings</mat-icon>
          </button>
        </li>
        <li>
          <button mat-icon-button>
            <mat-icon>help_outline</mat-icon>
          </button>
        </li>
      -->
        <li>
          <button mat-button [routerLink]="['/about']">
            <mat-icon>help_outline</mat-icon> About
          </button>
          <!--
              <button mat-button [matMenuTriggerFor]="menu"
          [routerLink]="/about">
          <mat-menu #menu="matMenu">
            <button mat-menu-item>By Laws</button>
            <button mat-menu-item>Consitution</button>

            <button mat-menu-item>
              <mat-icon>exit_to_app</mat-icon>Sign Out
            </button>
          </mat-menu>
          -->
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
  <mat-toolbar-row class="yellow-divide"><span> </span></mat-toolbar-row>
</mat-toolbar>
