<div>
  <h2>Considering Marriage??</h2>
  <mat-card>
    <mat-card-title> Wedding Services </mat-card-title>
    <mat-card-content>
      Pre-marriage counseling<br />
      Ceremony and reception planning<br />

    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
  <mat-divider></mat-divider>
  <mat-card>
    <p>
      &quot;Simplicity, patience, compassion.<br />
      These three are your greatest treasures.<br />
      Simple in actions and thoughts, you return to the source of being.<br />
      Patient with both friends and enemies,<br />
      you accord with the way things are.<br />
      Compassionate toward yourself,<br />
      you reconcile all beings in the world.&quot;<br />
      (Tao Te Ching)<br />
    </p>
  </mat-card>
  <mat-divider></mat-divider>

  <mat-card>  Love is patient; love is kind; love is not envious or boastful or
    arrogant or rude. It does not insist on its own way; it is not irritable
    or resentful; it does not rejoice in wrongdoing, but rejoices in the
    truth. It bears all things, believes all things, hopes all things,
    endures all things.
    <br />1 Corinthians:4-7 (NRSV)</mat-card>
  <mat-card> Be united in faith, spirit and fullness of grace. </mat-card>
</div>
