<div>
  <h2>Outreach</h2>
  <mat-card>
    <mat-card-title> Be the Church in the community </mat-card-title>
    <mat-card-content>
      Urban Bicycle Food Ministry<br />
      Interfaith Alliance<br />
      Open and Affirmaing Coalition<br />
      Food Pantry of Central Iowa<br />
      <br />
      Support Ukraine<br />
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>
  <mat-divider></mat-divider>

  <mat-card> Monthly givings | Planned Bike Rides </mat-card>
  <mat-card>
    because together we are more than just a church. We serve the One that
    created us so that we can share life that sustains and creates joy in the
    world
  </mat-card>
</div>
