<div>
    <h2>Fitness and Body Health</h2>
    <mat-card>
      <mat-card-title> Training and health discipline </mat-card-title>
      <mat-card-content>
        Meditation<br />
        Martial arts<br />
        Bicycling<br />
        Kickboxing<br />
        Circuit Training<br />
        Bootcamp Core<br />
      </mat-card-content>
      <mat-card-footer>
        No discipline seems pleasant at the time, but painful.
        Later on, however, it produces a harvest of righteousness and peace for
        those who have been trained by it. (Hebrews 12:11)
      </mat-card-footer>
    </mat-card>
  <mat-divider></mat-divider>

  <mat-card>
   Weekly Meditations | Planned Bike Rides
  </mat-card>
</div>
