<div>
  <mat-card>
    <h1>Faith Community Church</h1>
    (a Hope and Truth Ministry)<br />
    <br />
    Create in me a clean heart, O God, and put a new and right spirit within me.
    Psalm 51:10 (NRSV)<br />
    May the words of my mouth and the meditations of all of our hearts be
    acceptable in Your sight. adapted from Psalm 19 (NRSV)<br />
  </mat-card>
  <mat-card>    Thank you for visiting!</mat-card>
  <mat-card>
    Are you in a wilderness?? Not sure what path to take? <br />
    Faith Community ministry dedicated to faith, hope and love that spans
    cultures, sacred ideas and provides direction to promote wellness and
    healing of spirit, body and mind in the many ways our Creator gifts us.
  </mat-card>

</div>
