<div>
  <h2>Devotionals </h2>
  <mat-card>
    <mat-card-title> Daily inspiration </mat-card-title>
    <mat-card-content>
      Richard Rohr's Center for Contemplative Prayer<br />
      Robert Wright's Nonzero Buddhist Blog<br />
      God Pause from Luther Seminary<br />
      Daily Devotional from the United Church of Christ<br />
      <br />

    </mat-card-content>
    <mat-card-footer>
         </mat-card-footer>
  </mat-card>
<mat-divider></mat-divider>

<mat-card>
 Listen for the still speaking God
</mat-card>
</div>
