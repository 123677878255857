<div>
  <mat-card>
    <h1>Faith Community Church</h1>
    (a Hope and Truth Ministry)<br />
  </mat-card>
  <mat-card>
    Are you in a wilderness?? Not sure what path to take? <br />
    Faith Community ministry dedicated to faith, hope and love that spans
    cultures, sacred ideas and provides direction to promote wellness and
    healing of spirit, body and mind in the many ways our Creator gifts us.
  </mat-card>
  <mat-card>
    <mat-card-header><h3>Mission &amp; Vision</h3> </mat-card-header>
    <mat-card-content>
      The Faith Community Church mission is to provide a truly welcoming, open
      and affirming ministry to all people seeking divine wisdom, no matter
      where one is on life’s journey We are committed to theology that affirms
      the gospel teachings and wisdom that affirms differences (rather than
      rejecting them) in how people come to our higher power and how people
      believe in the Creator and sustainer of all life. This church community
      values equity, access, fairness and positive ideals - leaving judgment to
      that Creator. Thus, we serve the community in capacities that enrich lives
      and advocate for causes in the way the Spirit teaches us in the Gospels
      and the many great wisdoms around the world who share in the universal
      justice and truth.
    </mat-card-content>
  </mat-card>
  <mat-card>
    We believe our purpose is to:
    <ul>
      <li>grow in love of the Holy Presence and of all our Neighbors,</li>
      <li>
        provide a safe, sacred and faith-respective atmosphere though abundant
        welcoming and love of that Creator revealed in us,
      </li>
      <li>
        promote, through advocacy and education positive and social awareness of
        issues and collective vision to the congregation and outreach to the
        community,
      </li>
      <li>
        be an advocate for equal civil rights, diversity, enabled society, and
        social justice,
      </li>
      <li>
        advocate for commonality and interfaith relations with non-traditional
        Christian teachings,
      </li>
      <li>
        participate and develop coalitions with other organizations that foster
        social justice, the Gospels, and good citizenry.
      </li>
    </ul>
  </mat-card>
</div>
