<div fxFlex flexLayout="row" fxLayoutAlign="space-around center">
  <mat-card>
    <ul>
      <li style="list-style: none"><a href="#">About Faith Community</a></li>
      <li style="list-style: none">
        <a href="http://www.tigersndragons.com/orgstartup.html"
          >Nonprofit Resources</a
        >
      </li>
      <li style="list-style: none">
        <a href="http://www.tigersndragons.com">Tech Services</a>
      </li>
    </ul>
  </mat-card>
  <mat-card id="contact">
    Faith Community Church<br />
    Des Moines, IA<br />
    <a
      href="https://www.linkedin.com/pub/tony-dillon-hansen/8/3b/265"
      target="_new"
    >
      <img src="assets/img/LinkedIn-40.gif"
    /></a>
  </mat-card>
  <mat-card>
    <a href="#" target="_new">Privacy</a> |
    <a href="#" target="_new">Terms of Use</a><br/>

    &copy; Copyright 2016-2022<br />
    Faith Community Church | Hope and Truth Ministry <br />
    All Rights Reserved.
    <a href="http://www.tigersndragons.com">A BigKittyMeows Production</a>
  </mat-card>
</div>

<!--footer -->
